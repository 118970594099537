import Vue from 'vue'
import article from "../../components/public/article/article-list";

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector("#article-list")
    if (!el) return

    const params = JSON.parse(el.dataset.params || '{}')
    const limit = parseInt(el.dataset.limit ) || 0
    let page = parseInt(el.dataset.page) || 1

    const app = new Vue({
        render: h => h(article, {
            props: {
                searchParams: params,
                limit: limit,
                page: page
            }}),
    }).$mount();
    el.appendChild(app.$el);
});
